.swiper[data-carousel="cards"] {
    margin-right: calc(((100vw - var(--narrow-width)) / -2) - var(--site-gutter));

    @include breakpoint(tablet) {
        margin-right: 0;
    }

    @include breakpoint(mobile) {
        margin-right: calc(var(--site-gutter-mobile) * -1);
    }
}
