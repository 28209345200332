.banner {
    position: relative;

    @include breakpoint(phablet) {
        margin-left: calc(var(--site-gutter-mobile) * -1);
        margin-right: calc(var(--site-gutter-mobile) * -1);
    }
}

.banner--alt {
    &::after {
        content: '';
        position: absolute;
        left: 25px;
        bottom: 25px;
        top: 25px;
        right: 25px;
        border: 1px solid $c-white;
    }

    @include breakpoint(tablet) {
        text-align: center;

        &::after {
            display: none;
        }
    }
}

    .banner__image {
        aspect-ratio: 4 / 1;
        filter: brightness(0.6);
        position: relative;

        .banner--alt & {
            aspect-ratio: 10 / 3;

            @include breakpoint(nav) {
                aspect-ratio: 3 / 1;
            }
        }

        @include breakpoint(nav) {
            aspect-ratio: 3 / 1;
        }

        @include breakpoint(tablet) {
            aspect-ratio: 7 / 8;

            .banner--alt & {
                aspect-ratio: 7 / 9;
            }

            .banner--has-secondary-image & {
                aspect-ratio: 1 / 1;
            }
        }

        @include breakpoint(phablet) {
            .banner--has-secondary-image & {
                aspect-ratio: 2 / 3;
            }
        }

        @include breakpoint(mobile) {
            .banner--has-secondary-image & {
                aspect-ratio: 1 / 2;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .banner__secondary-image {
        border: 10px solid $c-white;
        position: absolute;
        right: 110px;
        top: 50%;
        transform: translateY(-50%);

        @include breakpoint(nav) {
            right: 40px;
        }

        @include breakpoint(tablet) {
            top: 40px;
            left: 50%;
            transform: translate(-50%, 0);
            aspect-ratio: 7 / 10;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .banner__secondary-image--portrait {
        width: 210px;
    }

    .banner__secondary-image--landscape {
            width: 320px;

        @include breakpoint(nav) {
            width: 250px;
        }

        @include breakpoint(tablet) {
            top: 40px;
            left: 50%;
            transform: translate(-50%, 0);
            aspect-ratio: 10 / 7;
        }

        @include breakpoint(mobile) {
            max-width: 60%;
        }
    }

    .banner__content {
        z-index: 1;
        max-width: var(--narrow-width);
        padding: 0 var(--site-gutter);
        margin: 0 auto;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        display: grid;
        grid-template-columns: 1fr auto;
        align-content: center;
        align-items: center;
        column-gap: 100px;

        @include breakpoint(tablet) {
            max-width: none;
            padding: 0 var(--site-gutter-mobile);
            grid-template-columns: 1fr;
            left: 0;
            transform: none;
        }

        .banner--alt & {
            grid-template-columns: 360px auto;
            padding: 0 70px;

            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
                padding: 0 var(--site-gutter-mobile);
            }
        }

        .banner--has-secondary-image & {
            grid-template-columns: 1fr 350px;

            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
                align-content: flex-end;
                padding-bottom: 30px;
            }
        }
    }

        .banner__subtitle {
            @include subtitle;
            @include mediumText;

            margin-bottom: 5px;
            grid-column: 1 / 2;
            color: $c-white;
        }

        .banner__title {
            @include medium-title--unresponsive;

            margin-bottom: 20px;
            grid-column: 1 / 2;
            color: $c-white;

            @include breakpoint(tablet) {
                margin-bottom: 10px;
            }

            .banner--alt & {
                grid-row: span 2;

                @include breakpoint(tablet) {
                    grid-row: span 1;
                    margin-bottom: 20px;
                }
            }
        }

        .banner__meta {
            display: grid;
            column-gap: 50px;

            @include breakpoint(tablet) {
                grid-template-columns: 1fr;
                text-align: center;
            }

            dt {
                @include subtitle;

                color: $c-highlight;
                grid-row: 1 / 2;

                @include breakpoint(tablet) {
                    grid-row: auto;

                    &:last-of-type {
                        margin-top: 25px;
                    }
                }
            }

            dd {
                @include tiny-title;

                color: $c-white;
            }
        }

        .banner__description {
            grid-column: 1 / 2;
            color: $c-white;

            @include breakpoint(tablet) {
                margin-bottom: 25px;
            }

            .banner--alt & {
                @include font(20px, 30px);
                @include apply-map($f-title);

                grid-column: 2 / 3;

                @include breakpoint(tablet) {
                    grid-column: span 1;
                    text-align: center;
                    margin: 15px 0;
                }
            }
        }

        .banner__cta {
            grid-column: 2 / 3;
            grid-row: 2 / 3;

            @include breakpoint(tablet) {
                grid-column: 1 / 2;
                grid-row: auto;
                justify-self: flex-start;
            }

            .banner--alt & {
                grid-row: auto;
                margin-top: 15px;
                justify-self: flex-start;

                @include breakpoint(tablet) {
                    justify-self: center;
                }
            }

            .banner--has-secondary-image & {
                grid-row: auto;
                grid-column: 1 / 2;
                margin-top: 15px;
                justify-self: flex-start;
            }
        }
