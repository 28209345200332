.card {
    position: relative;
    display: block;

    &::after {
        content: '';
        position: absolute;
        left: 35px;
        top: 35px;
        width: 1px;
        height: 40%;
        max-height: 200px;
        background-color: $c-white;

        @include breakpoint(tablet) {
            display: none;
        }
    }
}

.card--no-carousel {
    &::after {
        height: 70px;
    }
}

    .card__count {
        @include mediumText;

        position: absolute;
        top: 25px;
        right: 30px;
        z-index: 1;
        color: $c-white;

        @include breakpoint(tablet) {
            top: 15px;
            right: 10px;
        }
    }

    .card__image {
        aspect-ratio: 2 / 3;
        filter: brightness(0.55);
        overflow: hidden;

        img {
            width: 100%;
            height: auto;
            transition: transform 500ms ease-in;

            .card:hover & {
                transform: scale(1.075);
            }
        }
    }

    .card__content {
        position: absolute;
        display: flex;
        flex-direction: column;
        left: 35px;
        right: 20px;
        bottom: 65px;
        max-width: 300px;

        @include breakpoint(tablet) {
            left: 20px;
            bottom: 35px;
        }

        .card--no-carousel & {
            top: 120px;
            bottom: 40px;

            @include breakpoint(tablet) {
                top: unset;
                bottom: 30px;
            }
        }

        h3 {
            @include medium-title--unresponsive;

            margin-bottom: 10px;
            color: $c-white;
        }

        p {
            @include mediumText;

            color: $c-white;

            &:not(:last-child) {
                margin-bottom: 40px;

                @include breakpoint(tablet) {
                    margin-bottom: 20px;
                }

                .card--no-carousel & {
                    margin-bottom: auto;

                    @include breakpoint(tablet) {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
