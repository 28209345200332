@import 'swiper/card-carousel';

.card-carousel {}

    .card-carousel__heading {
        display: flex;
        flex-wrap: wrap;
        position: relative;
    }

        .card-carousel__subtitle {
            @include subtitle;

            margin-bottom: 5px;
            flex-basis: 100%;

            @include breakpoint(tablet) {
                margin-bottom: 0;
            }

            .card-carousel--no-carousel & {
                text-align: center; 
            }
        }

        .card-carousel__title {
            @include large-title;

            @include breakpoint(tablet) {
                padding-right: 100px;
            }

            @include breakpoint(mobile) {
                padding-right: 0;
            }

            .card-carousel--no-carousel & {
                text-align: center;
                flex-basis: 100%;
            }
        }

        .card-carousel__nav-wrap {
            display: flex;
            gap: 10px;
            margin-left: auto;
            list-style: none;

            @include breakpoint(tablet) {
                position: absolute;
                right: 0;
                bottom: 0;
            }
        }

        .card-carousel__prev,
        .card-carousel__next {
            @include carousel-arrow;
        }

    .card-carousel__carousel {
        margin-top: 40px;

        @include breakpoint(tablet) {
            margin-top: 25px;
        }
    }

    .card-carousel__container--no-carousel {
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        gap: 15px;

        @include breakpoint(nav) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

        @include breakpoint(mobile) {
            grid-template-columns: 1fr;
        }
    }

    .card-carousel__quote {
        width: 75%;
        margin-top: 60px;

        @include breakpoint(tablet) {
            width: 100%;
        }

        p {
            @include small-title--alt;

            margin-bottom: 15px;

            &:before,
            &::after {
                content: '"';
            }
        }

        cite {
            @include subtitle;
        }
    }
