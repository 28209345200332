.article-preview {
    position: relative;
}

    .article-preview__image {
        aspect-ratio: 5 / 8;
        filter: brightness(0.55);
        overflow: hidden;

        @include breakpoint(tablet) {
            aspect-ratio: 3 / 2;
        }

        img {
            width: 100%;
            height: auto;
            transition: transform 500ms ease-in;

            .article-preview a:hover & {
                transform: scale(1.075);
            }
        }
    }

    .article-preview__image--landscape {
        aspect-ratio: 2 / 1;

        @include breakpoint(tablet) {
            aspect-ratio: 3 / 2;
        }
    }

    .article-preview__category {
        @include subtitle;

        position: absolute;
        top: 0;
        left: 35px;
        right: 35px;
        padding: 20px 0;
        text-align: center;
        color: $c-white;
        border-bottom: 1px solid $c-highlight;

        @include breakpoint(tablet) {
            left: 45px;
            right: 45px;
        }
    }

    .article-preview__text {
        position: absolute;
        bottom: 40px;
        left: 10px;
        right: 10px;
        text-align: center;

        @include breakpoint(tablet) {
            bottom: 20px;
        }
    }

        .article-preview__title {
            @include small-title--unresponsive;

            color: $c-white;
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                margin-bottom: 15px;
            }
        }
