@mixin carousel-arrow {
    color: var(--theme-colour);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid currentColor;
    cursor: pointer;

    @include breakpoint(tablet) {
        width: 40px;
        height: 40px;
    }

    svg {
        fill: currentColor;
    }

    &[disabled] {
        color: $c-border-grey;
    }
}
