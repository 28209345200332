.review-carousel {
    position: relative;
    text-align: center;

    svg[class^='icon-quote'] {
        position: absolute;
        top: calc(50% - 30px); // not quite center
        transform: translateY(-50%);

        @include breakpoint(tablet) {
            display: none;
        }
    }

    svg.icon-quote-left {
        left: 120px;
    }

    svg.icon-quote-right {
        right: 120px;
    }
}

    .review-carousel__title {
        @include subtitle;

        margin-bottom: 10px;
    }

    .review-carousel__container {
        margin: 0 auto;
    }

        .review-carousel__item {
            & > * {
                padding: 0 20%;

                @include breakpoint(tablet) {
                    padding: 0;
                }
            }
        }

            .review-carousel__item-title {
                @include small-title--unresponsive;

                color: $c-black;
                margin-bottom: 30px;
            }

            .review-carousel__item-text {
                margin-bottom: 10px;
            }

            .review-carousel__item-stars {
                display: flex;
                justify-content: center;
                gap: 2px;
                margin-bottom: 15px;

                img {
                    width: 20px;
                    height: auto;
                }
            }

            .review-carousel__item-cite {
                @include font(14px, 20px);
                @include mediumText;

                text-transform: uppercase;
                letter-spacing: 1.25px;
                margin-bottom: 10px;
                display: block;

                @include breakpoint(tablet) {
                    @include font(12px, 16px);
                }
            }

            .review-carousel__item-date {
                @include font(12px, 16px);
                @include mediumText;

                text-transform: uppercase;
                color: $c-light-grey;
                display: block;
            }

    .review-carousel__nav-wrap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;

        @include breakpoint(tablet) {
            margin-top: 20px;
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            transform: none;
            justify-content: center;
            gap: 10px;
        }
    }

        .review-carousel__prev,
        .review-carousel__next {
            @include carousel-arrow;
        }


