.featured-collection {
    position: relative;
    grid-column: 1 / 4;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include breakpoint(tablet) {
        grid-column: 1 / 2;
    }

    a {
      text-decoration: none;
    }
}

.featured-collection--blog-listing {
    grid-column: 1 / 5;

    @include breakpoint(phablet) {
        grid-column: 1 / 2;
        margin-bottom: 5px;
    }
}

    .featured-collection__image {
        aspect-ratio: 7 / 3;
        grid-row: 1;
        grid-column: 1;
        z-index: -1;

        @include breakpoint(tablet) {
            aspect-ratio: 5 / 6;
        }

        @include breakpoint(phablet) {
            margin-left: calc(var(--site-gutter-mobile) * -1);
            margin-right: calc(var(--site-gutter-mobile) * -1);
        }

        @include breakpoint(mobile) {
            aspect-ratio: unset;
        }

        img {
            width: 100%;
            height: auto;

            @include breakpoint(mobile) {
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .featured-collection__category {
        @include subtitle;

        color: $c-black;
        min-width: 50%;
        display: inline-block;
        padding: 20px 0;
        margin-bottom: 30px;
        text-align: center;
        border-bottom: 1px solid $c-highlight;
    }

    .featured-collection__content {
        @include offset-border;

        max-width: 560px;
        grid-row: 1;
        grid-column: 1;
        position: relative;
        place-self: center;
        padding: 70px 50px 60px;
        background-color: $c-white;
        text-align: center;

        @include breakpoint(tablet) {
            padding: 40px 15px;
            max-width: 100%;
            margin-right: 15px;
            margin-left: 30px;
        }

        @include breakpoint(phablet) {
            margin-right: 0;    
            margin-left: 15px;
        }

        @include breakpoint(mobile) {
            margin-top: 60px;
            margin-bottom: 60px;
        }

        .featured-collection--blog-listing & {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

        .featured-collection__title {
            @include medium-title;

            margin-bottom: 20px;
            color: $c-black;
        }

        .featured-collection__text {
            @include intro;

            margin-bottom: 20px;
        }
