.who-we-are {
    position: relative;

    @include breakpoint(phablet) {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
}

    .who-we-are__image {
        position: relative;
        aspect-ratio: 9 / 4;
        filter: brightness(0.55);

        @include breakpoint(nav) {
            aspect-ratio: 5 / 3;
        }

        @include breakpoint(tablet) {
            aspect-ratio: 1 / 1;

        }

        @include breakpoint(phablet) {
            aspect-ratio: unset;
            grid-row: 1;
            grid-column: 1;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .who-we-are__content {
        max-width: var(--narrow-width);
        padding: 0 var(--site-gutter);
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
            gap: 50px;
            padding: 0 var(--site-gutter-mobile);
        }
 
        @include breakpoint(phablet) {
            position: relative;
            top: unset;
            left: unset;
            right: unset;
            transform: none;
            grid-row: 1;
            grid-column: 1;
            padding-top: 40px;
            padding-bottom: 50px;
        }
    }

        .who-we-are__callout {
            @include offset-border;

            background-color: $c-white;
            padding: 60px;
            padding-bottom: 50px;
            position: relative;

            @include breakpoint(tablet) {
                padding: 35px 20px;
                text-align: center;
                margin: 0 15px;
            }
        }

            .who-we-are__subtitle {
                @include subtitle;
            }

            .who-we-are__title {
                @include medium-title--unresponsive;

                margin-bottom: 20px;
                color: $c-black;

                @include breakpoint(tablet) {
                    margin-bottom: 10px;
                }
            }

            .who-we-are__intro {
                @include intro;
                @include mediumText;

                margin-bottom: 20px;

                @include breakpoint(tablet) {
                    margin-bottom: 15px
                }
            }

            .who-we-are__intro--white {
                @include regularText;

                margin-right: -50px;

                @include breakpoint(nav) {
                    margin-right: 0;
                }

                @include breakpoint(tablet) {
                    @include font(15px, 24px);

                    padding: 0 15px;
                    text-align: center;
                }
            }

            .who-we-are__body {
                margin-bottom: 20px;

                @include breakpoint(tablet) {
                    display: none;
                }
            }

        .who-we-are__profiles {
            margin-left: 60px;

            @include breakpoint(tablet) {
                margin: 0;
            }
        }

            .who-we-are__staff {
                display: flex;
                flex-wrap: wrap;
                gap: 40px;
                justify-content: center;

                @include breakpoint(tablet) {
                    gap: 25px;
                    margin-top: 45px;

                    & > * {
                        flex-basis: calc(33.33% - 17px);
                    }
                }

                li {
                    width: 130px;
                    height: 130px;

                    @include breakpoint(tablet) {
                        max-width: 95px;
                        max-height: 95px;
                    }
                }

                a {
                    display: block;
                    overflow: hidden;
                    border-radius: 50%;

                    &:hover img {
                        transform: scale(1.075);
                    }
                }

                img {
                    width: 100%;
                    height: auto;
                    border-radius: 50%;
                    transition: transform 500ms ease-in;
                    filter: grayscale(1);
                }
            }
