.collection-item {
    display: block;
    text-decoration: none;
    color: $c-black;
}

    .collection-item__image {
        aspect-ratio: 1;
        margin-bottom: 20px;
        overflow: hidden;

        @include breakpoint(tablet) {
            aspect-ratio: 7 / 4;
        }

        img {
            width: 100%;
            height: auto;
            transition: transform 500ms ease-in;

            .collection-item:hover & {
                transform: scale(1.075);
            }
        }
    }

    .collection-item__content {
        text-align: left;
        padding: 15px 20px;
        border-left: 1px solid $c-highlight;

        @include breakpoint(tablet) {
            padding-right: 0;
        }
    }

        .collection-item__title {
            @include medium-title;

            color: $c-black;
            margin-bottom: 15px;
        }

        .collection-item__text {
            margin-bottom: 20px;
        }
