.collection {
    text-align: center;
}

    .collection__subtitle {
        @include subtitle;
    }

    .collection__title {
        @include large-title--alt;

        margin-bottom: 40px;

        @include breakpoint(tablet) {
            margin-bottom: 20px;
        }
    }
    
    .collection__grid {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        column-gap: 15px;
        row-gap: 35px;

        @include breakpoint(tablet) {
            grid-template-columns: minmax(0, 1fr);
            row-gap: 20px;

            & > .collection-item:last-child {
                display: none;
            }
        }
    }

    .collection__small-title {
        @include small-title--unresponsive;

        margin-top: 65px;
        margin-bottom: 25px;

        @include breakpoint(tablet) {
            margin-top: 40px;
        }
    }
