.logo-carousel {
    position: relative;
    max-width: var(--narrow-width);
    margin: 0 auto;
}

.logo-carousel--has-cta ~ .logo-carousel__prev,
.logo-carousel--has-cta ~ .logo-carousel__next {
    bottom: 80px;
}

    .logo-carousel__subtitle {
        @include subtitle;

        margin-bottom: 10px;
    }

    .logo-carousel__title {
        @include large-title;

        margin-bottom: 50px;

        @include breakpoint(tablet) {
            margin-bottom: 15px;
        }
    }

    .logo-carousel__container {
        align-items: center;
        justify-content: center;

        @include breakpoint(tablet) {
            max-width: none;
            margin-bottom: 40px;
        }
    }

        .logo-carousel__logo {
            figure {
                height: 90px;

                .logo-carousel--grey-bg & {
                    background-color: $c-background;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;

                .logo-carousel--grey-bg & {
                    mix-blend-mode: multiply;
                }
            }
        }

        .logo-carousel__prev,
        .logo-carousel__next {
            @include carousel-arrow;

            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;

            @include breakpoint(tablet) {
                display: inline-flex;
                top: unset;
                bottom: 0;
            }
        }

        .logo-carousel__prev {
            left: calc(((100vw - var(--narrow-width)) / -2) + var(--site-gutter));

            @include breakpoint(wrap) {
                left: calc((1520px - 1220px) / -2);
            }

            @include breakpoint(tablet) {
                left: calc(50% - 25px);
                transform: translate(-50%, 0);
            }
        }

        .logo-carousel__next {
            right: calc(((100vw - var(--narrow-width)) / -2) + var(--site-gutter));

            @include breakpoint(wrap) {
                right: calc((1520px - 1220px) / -2);
            }

            @include breakpoint(tablet) {
                right: unset;
                left: calc(50% + 25px);
                transform: translate(-50%, 0);
            }
        }

    .logo-carousel__cta {
        margin-top: 40px;

        @include breakpoint(tablet) {
            margin-top: 100px;
        }
    }
        
