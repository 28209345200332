@mixin offset-border($color: $c-highlight, $offset: 25px, $edges: (bottom, left)) {
    &::before {
        content: '';
        position: absolute;
        border: 1px solid $color;
        z-index: -1;

        // Handle two-edge case
        @if index($edges, top) {
            top: -$offset;
            bottom: $offset;

            @include breakpoint(tablet) {
                top: -15px;
                bottom: 15px;
            }
        }

        @if index($edges, right) {
            right: -$offset;
            left: $offset;

            @include breakpoint(tablet) {
                right: -15px;
                left: 15px;
            }
        } 

        @if index($edges, bottom) {
            bottom: -$offset;
            top: $offset;

            @include breakpoint(tablet) {
                bottom: -15px;
                top: 15px;
            }
        } 

        @if index($edges, left) {
            left: -$offset;
            right: $offset;

            @include breakpoint(tablet) {
                left: -15px;
                right: 15px;
            }
        }
    }
}
