.article-grid {}

    .article-grid__content {
        text-align: center;

        @include breakpoint(tablet) {
            margin: 0 auto 30px;
        }

        .article-grid--fancy & {
            margin-bottom: -50px;
        }
    }

        .article-grid__subtitle {
            @include subtitle;

            margin-bottom: 5px;
        }

        .article-grid__title {
            @include medium-title;

            color: $c-black;
            margin-bottom: 20px;

            @include breakpoint(tablet) {
                margin: 0 auto 10px;
                width: 75%;
            }
        }

        .article-grid__intro {
            @include intro;

            margin-bottom: 30px;

            @include breakpoint(tablet) {
                margin-bottom: 20px;
            }
        }

    .article-grid__items {
        display: flex;
        justify-content: space-between;
        column-gap: 15px;

        @include breakpoint(tablet) {
            flex-direction: column;
            row-gap: 30px;
        }

        .article-grid--tight & {
            margin-top: 30px;
        }

        & > * {
            flex: 1;
        }

        .article-grid--fancy & {
            column-gap: 70px;

            & > * {
                &:nth-child(1) {
                    margin-bottom: 90px;

                    @include breakpoint(tablet) {
                        margin: 0;
                    }
                }

                &:nth-child(2) {
                    margin-top: 90px;

                    @include breakpoint(tablet) {
                        margin: 0;
                    }
                }

                &:nth-child(3) {
                    margin-bottom: 90px;

                    @include breakpoint(tablet) {
                        display: none;
                    }
                }
            }
        }
    }
