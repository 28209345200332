.intro {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    @include breakpoint(tablet) {
        padding-left: 25px;
        padding-right: 25px;
        flex-direction: column;
        gap: 0;
    }
}

    .intro__image {
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        overflow: hidden;
        max-width: 420px;
        flex-grow: 1;

        img {
            width: 100%;
            height: auto;
        }
    }

    .intro__image--left {
        margin-left: -90px;

        @include breakpoint(tablet) {
            margin: 0;
            margin-bottom: -110px;
            height: 290px;
            width: 290px;
            border-radius: 50%;
            order: 1;
        }
    }

    .intro__image--right {
        margin-right: -90px;

        @include breakpoint(tablet) {
            display:  none;
        }
    }

    .intro__content {
        text-align: center;
        max-width: 650px;
        margin: 0 auto;
    }

        .intro__subtitle {
            @include subtitle;
        }

        .intro__title {
            @include medium-title;

            margin-top: 10px;
            color: $c-black;
        }

        .intro__text {
            @include intro--unresponsive;

            margin: 30px auto 0;
            width: 80%;

            @include breakpoint(tablet) {
                width: 100%;
                margin: 20px 0 25px;
            }
        }
